import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "React Podcast",
  "date": "2020-05-28",
  "promo": "grids",
  "pic": "/img/podcast.jpg",
  "cover": "/img/cover/podcast.jpg",
  "description": "(podcast) The key to learning",
  "color": "#ff4783"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I recently had the joy of hanging out with `}<a parentName="p" {...{
        "href": "https://twitter.com/chantastic"
      }}>{`chantastic`}</a>{` on the latest React Podcast episode. We talked about the science of learning, the importance of sleep, my journey from employee to entrepreneur and more. `}</p>
    <p>{`Here's the episode. Grab a cold diet coke and have a listen:`}</p>
    <Action link="https://reactpodcast.simplecast.com/episodes/95" mdxType="Action">Listen to the podcast</Action>
    <p>{`Enjoy!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      